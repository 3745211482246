/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class DepositTypeService {
  getDepositTypeLists(queryParams) {
    const pars = {
      // name: queryParams.name,
      // id: queryParams.id,
      // name_en: queryParams.name_en,
      // logo: queryParams.logo,
      // status: queryParams.status,
    };
    return instance
      .get("/api/deposit-type/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          depositTypes: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }

  updateDepositType(depositTypeData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", depositTypeData.id);
    data.append("name", depositTypeData.name);
    data.append("name_en", depositTypeData.name_en);
    data.append("status", depositTypeData.status);
    data.append("logo", depositTypeData.logo && depositTypeData.logo != 'undefined' ? depositTypeData.logo : '');

    return instance.post("/api/deposit-type/update", data, {
      headers: authHeader(),
    });
  }
}

export default new DepositTypeService();
