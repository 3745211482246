import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function useDepositTypeList() {
  const refDepositTypeListTable = ref(null);

  const tableColumns = [
    { key: "index", label: "#" },
    { key: "name", label: i18n.t("name") },
    { key: "name_en", label: i18n.t("English Name") },
    { key: "logo", label: i18n.t("Logo") },
    { key: "stat", label: i18n.t("Status") },
    { key: "created_at", label: i18n.t("Created at") },
    { key: "updated_at", label: i18n.t("Updated at") },
    { key: "action", label: i18n.t("Actions") },
  ];
  const perPage = ref(25);
  const totalDepositType = ref(0);
  const currentPage = ref(1);

  const refetchData = () => {
    refDepositTypeListTable.value.refresh();
  };

  watch([currentPage], () => {
    refetchData();
  });

  const fetchDepositType = (ctx, callback) => {
    store
      .dispatch("payment-deposit-type/fetchDepositType", {
        page: currentPage.value,
        perPage: perPage.value,
      })
      .then((response) => {
        const { depositTypes, total, pageSize } = response;
        callback(depositTypes);
        totalDepositType.value = total;
        perPage.value = pageSize;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching bank accounts list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const dataMeta = computed(() => {
    const localItemsCount = refDepositTypeListTable.value
      ? refDepositTypeListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDepositType.value,
    };
  });

  const resolveStatus = (status) => {
    if (status === 1) return { label: i18n.t("Active"), variant: "success" };
    if (status === 2) return { label: i18n.t("Inactive"), variant: "danger" };
  };

  return {
    fetchDepositType,
    // postCategories,
    tableColumns,
    perPage,
    currentPage,
    totalDepositType,
    dataMeta,
    refDepositTypeListTable,
    refetchData,
    resolveStatus,
  };
}
