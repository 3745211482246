import DepositTypeService from "@/libs/deposit-type.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDepositType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        DepositTypeService.getDepositTypeLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateDepositType(ctx, DepositTypeData) {
      return new Promise((resolve, reject) => {
        DepositTypeService.updateDepositType(DepositTypeData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
